<template>
    <div   >
        <!-- <div style="background:#E4E7ED;line-height:35px;height:35px;border-left:3px solid #409EFF;margin-top:1px;">
            <span style="margin-left:10px;color:#303133; font-size:14px;">查询条件</span>
        </div> -->

        <div style=" background:white;">
            <div style="width:1400px;  display:flex;align-items: center;height:40px;padding-left:20px; ">
                <div style="width:60px;margin-left:3px;">询价单号</div>
                <el-input placeholder="询价单号" v-model="condition.idno" size="small" style="width:140px;margin-right:20px;"></el-input>
                <span style=" width:60px;">询价品类</span>
                <el-input placeholder="询价品类" v-model="condition.mattynm" size="small" style="width:140px;margin-right:20px;"></el-input>
                <span style="width:60px;">询价品名</span>
                <el-input placeholder="询价品名" v-model="condition.matnm" size="small" style="width:140px;margin-right:20px;"></el-input>
                <span style="width:60px;">询价状态</span>
                <el-select   v-model="condition.stfg" size="small" style="width:140px;margin-right:20px;">
                    <el-option value="all" label="全部"> </el-option>
                    <el-option value="wait" label="报价中"></el-option> 
                    <el-option value="ok" label="已完成"></el-option>
                    <el-option value="part" label="部分报价"></el-option>  
                    <el-option value="cancel" label="作废"></el-option> 
                </el-select> 
                <span style=" width:60px;">询价日期</span>
                <el-date-picker
                    v-model="condition.dttm"
                    align="right"
                    type="date"
                    value-format="yyyy-MM-dd"
                    size="medium"
                    style="width:160px;margin-right:5px;"
                    placeholder="选择日期"
                    :picker-options="pickerOptions">
                </el-date-picker> 
                <span style=" width:10px;">-</span>
                <el-date-picker
                    v-model="condition.todttm"
                    align="right"
                    type="date"
                    value-format="yyyy-MM-dd"
                    size="medium"
                    style="width:160px;margin-right:20px;"
                    placeholder="选择日期"
                    :picker-options="pickerOptions">
                </el-date-picker>
                
                <el-button @click="btnQry" size="small" type="primary" icon="el-icon-search" style="margin-right:20px;">查询</el-button>
                <el-button @click="btnClear" size="small" type="info" icon="el-icon-close" >重置</el-button>
            </div>
        </div>  
        <div> 
        <el-table  :data="reqData" 
              border                            
              size="small"
              v-loading="loading"
              element-loading-text="加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(255, 251, 255, 0.8)"
              :height="295" 
              :row-key="getRowKey"
              ref="refreq"
              highlight-current-row
              style="overflow:auto;margin-top:1px;"
              @row-click="rowClick" > 
 
                <el-table-column v-for="(item,index ) in reqCol.filter(item=>item.thide=='N')" :key="index"
                    :prop="item.key"
                    :label="item.title"
                    show-overflow-tooltip
                    :align="item.align"                                  
                    :sortable="item.key!=='RN'&&item.edcss!=='checkbox'"
                    :min-width="item.width">
                    <template  slot-scope="scope" >
                        <el-checkbox v-if ="item.edcss==='checkbox'"  disabled true-label="Y"  false-label="N" v-model="scope.row[item.key]" ></el-checkbox>
                        <span v-else v-text="scope.row[item.key]"></span>
                    </template>
                </el-table-column>
 
 
             <el-table-column
                fixed="right"
                label="操作"
                align="center"
                width="210">
                <template slot-scope="scope">
                    <el-button size="mini" v-if="scope.row['STFG']=='wait'||scope.row['STFG']=='part'"  type="text" icon="iconfont icon-chenggong" @click="stfgRec(scope.row,'ok')">已报价</el-button>
                    <el-button size="mini" v-if="scope.row['STFG']=='wait'"  type="text" icon="iconfont icon-dengdaiqueren" @click="stfgRec(scope.row,'part')">部份报价</el-button>
                    <el-button size="mini" v-if="scope.row['STFG']=='ok'||scope.row['STFG']=='part'"  type="text" icon="iconfont icon-jiazaizhong1" @click="stfgRec(scope.row,'wait')">报价中</el-button>
                    <el-button size="mini" v-if="scope.row['STFG']=='wait'"  type="text" icon="iconfont icon-shanchu1" @click="stfgRec(scope.row,'cancel')">作废</el-button>   
                </template>
            </el-table-column>  
        </el-table>
        </div>
        <el-tabs :animated="false" style="background:#fff">
            <el-tab-pane> 
                <span slot="label" style="padding-left:30px;"><i class="iconfont  icon-jichuziliao"></i> 主信息</span> 
                <div :style="{'height':subHeight,'overflow-y':'auto' }">
                    <Form :model="mainRow" :label-width="110" ref="refform"  label-position="right" inline >  
                        <span v-for="item  in reqCol" :key="item.key" >
                            <FormItem   :label="item.title"  class="item-width3" v-if="item.thide==='N'" :prop="item.key"  >                  
                                <el-checkbox disabled border v-if="item.edcss==='checkbox'" true-label="Y"  false-label="N" v-model="mainRow[item.key]" style="width:100%"></el-checkbox> 
                                <el-input v-else type="text" disabled   v-model="mainRow[item.key]" ></el-input>
                            </FormItem>
                        </span>
                    </Form>
                </div>
            </el-tab-pane>
            <el-tab-pane> 
                <span slot="label"><i class="iconfont  icon-chanchengpinku"></i> 产品</span> 
                <div :style="{'height':subHeight,'overflow-y':'auto' }">
                    <el-table  :data="dtlData" 
                        border                            
                        size="small"
                        v-loading="loading"
                        element-loading-text="加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(255, 251, 255, 0.8)"
                        :height="240" 
                        @row-click="dtlClick"
                        :row-key="getRowKey2"
                        ref="refdtl"
                        highlight-current-row
                        style="overflow:auto;margin-top:0px;"> 
                        <span v-for="(item ) in reqDtlCol" :key="item.key" > 
                            <el-table-column  v-if="item.thide==='N'&&item.edcss!=='checkbox'"
                                :prop="item.key"
                                :label="item.title"
                                show-overflow-tooltip
                                :align="item.align"                                  
                                sortable
                                :min-width="item.width">
                            </el-table-column> 
                            <el-table-column  v-if ="item.edcss==='checkbox'"
                                :prop="item.key"
                                :label="item.title"
                                :align="item.align"                                  
                                :min-width="item.width">
                                <template  slot-scope="scope" >
                                    <el-checkbox   disabled true-label="Y"  false-label="N" v-model="scope.row[item.key]" ></el-checkbox>
                                </template>
                            </el-table-column>
                        </span> 
                        <el-table-column
                            fixed="right"
                            label="操作"
                            align="center"
                            width="180">
                            <template slot-scope="scope">
                                <el-button size="mini" :disabled="scope.row['STFG']=='ok'||scope.row['STFG']=='cancel'"  type="text" icon="iconfont icon-fuzhi" @click="cpRec(scope.row ,scope.$index)">复制</el-button>
                                <el-button size="mini" :disabled="scope.row['STFG']=='ok'||scope.row['STFG']=='cancel'"  type="text" icon="iconfont icon-bianji" @click="editRec(scope.row ,scope.$index)">编辑</el-button>
                                <el-button size="mini" :disabled="scope.row['STFG']=='ok'||scope.row['STFG']=='cancel'"  type="text" icon="iconfont icon-shanchu1" @click="delRec(scope.row ,scope.$index)">删除</el-button>
                            </template>
                        </el-table-column>  
                    </el-table>
                    <el-tabs :animated="false" style="background:#fff">
                        <el-tab-pane> 
                            <span slot="label"><i class="iconfont  icon-icon-chaolianjie"></i> 附件</span>
                            <div style="display:flex;flex-direction:row;flex-wrap:wrap;" >
                                <div v-for="(item,index) in attArr" :key="index"  >
                                    <div v-if="item.FILEPATH.indexOf('pdf')>-1 ||item.FILEPATH.indexOf('PDF')>-1"> 
                                        <div style="width:160px;height:140px;border:1px solid #e8eaec;overflow-y:hidden;margin-left:15px;">
                                            <iframe :src="item.FILEPATH" frameborder="0" style="width: 100%; height: 100%"></iframe>
                                        </div>
                                        <div style="height:25px;background:#e8eaec;width:160px;margin-left:15px;margin-top:5px;">
                                            <el-link :underline="false"  @click="pdfViewer(item.FILEPATH)"><Icon type="ios-search" />查看</el-link>
                                        </div> 
                                    </div>
                                    <div v-else>
                                        <el-image fit="scale-down"  style="width:160px;height:140px;margin-left:15px;border:1px solid #e8eaec" :src="item.FILEPATH"></el-image>
                                        <div style="height:25px;background:#e8eaec;width:160px;margin-left:15px;">
                                            <el-link :underline="false" @click="imgPreview(item.FILEPATH)"><Icon type="ios-search" /> 预览</el-link>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        </el-tab-pane>
                        <el-tab-pane> 
                            <span slot="label"><i class="iconfont  icon-gongyingshangguanli"></i> 供应商</span> 
                            <el-table  :data="supData" 
                                border                            
                                size="small"                   
                                :height="tabHeight" 
                                highlight-current-row
                                style="overflow:auto;margin-top:1px;"> 
                                <span v-for="(item ) in reqSupCol" :key="item.key" > 
                                    <el-table-column  v-if="item.thide==='N' &&item.edcss!=='checkbox'"
                                        :prop="item.key"
                                        :label="item.title"
                                        show-overflow-tooltip
                                        :align="item.align"                                  
                                        sortable
                                        :min-width="item.width">
                                    </el-table-column> 
                                    <el-table-column  v-if ="item.edcss==='checkbox'"
                                        :prop="item.key"
                                        :label="item.title"
                                        :align="item.align"                                  
                                        :min-width="item.width">
                                        <template  slot-scope="scope" >
                                            <el-checkbox   disabled true-label="Y"  false-label="N" v-model="scope.row[item.key]" ></el-checkbox>
                                        </template>
                                    </el-table-column>
                                </span> 
                            </el-table>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </el-tab-pane> 
        </el-tabs>
         
        <!-- 编辑框 -->
        <el-dialog class="pub_dialog" v-dialogDrag :visible.sync="editWin" :width="tabWidth" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" top="4vh">
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'35px'}">
                <span style="line-height:30px; font-size:14px;padding-left:5px;">客户询价单回复</span>
            </div>
            <div style="overflow-y:auto;height:90vh; margin-top:-5px"> 
                <div style="display:flex;background:#E4E7ED;line-height:38px;height:38px;border-left:2px solid #409EFF; margin-bottom:2px;">
                    <span style="margin-left:10px;color:#303133; font-size:14px;">一、客户详情</span>
                </div>
                <Form :model="matRow" :label-width="120"  label-position="right" inline  >  
                    <span v-for="(item,index) in reqCol" :key="index" >
                        <FormItem   :label="item.title"  class="item-width3" v-if="item.fhide==='N'" :prop="item.key"  >                  
                            <el-select   v-if="item.key==='PRCTERM'"  v-model="matRow[item.key]" style="width:100%" filterable clearable>
                                <el-option v-for="item in prcTerm" :key="item.CODE" :value="item.CODE" :label="item.CNAME" ></el-option>
                            </el-select>
                            <el-select   v-else-if="item.key==='CHNNPRC'"  v-model="matRow[item.key]" style="width:100%" filterable clearable>
                                <el-option v-for="item in chnnPrc" :key="item.CODE" :value="item.CODE" :label="item.CNAME" ></el-option>
                            </el-select>
                            <el-select   v-else-if="item.key==='PAYTERM'"  v-model="matRow[item.key]" style="width:100%" filterable clearable>
                                <el-option v-for="item in payTerm" :key="item.CODE" :value="item.CODE" :label="item.CNAME" ></el-option>
                            </el-select>
                            <el-select   v-else-if="item.key==='PKGUNIT'"  v-model="matRow[item.key]" style="width:100%" filterable clearable>
                                <el-option v-for="item in pkgUnit" :key="item.CODE" :value="item.CODE" :label="item.CNAME" ></el-option>
                            </el-select>
                            <el-cascader v-else-if=" item.key==='NATION'" style="width:100%"
                                v-model="matRow[item.key]"
                                clearable
                                filterable   
                                :options="countryarea">
                            </el-cascader>
                            <el-checkbox  border v-else-if="item.edcss==='checkbox'" true-label="Y"  false-label="N" v-model="matRow[item.key]" style="width:100%"></el-checkbox> 
                            <el-input v-else type="text" :disabled="item.disabled==='Y'?true:false "  v-model="matRow[item.key]" ></el-input>
                        </FormItem>
                    </span>
                </Form>  
                <div style="display:flex;background:#E4E7ED;line-height:38px;height:38px;border-left:2px solid #409EFF; margin-bottom:2px;">
                    <span style="margin-left:10px;color:#303133; font-size:14px;">二、询价内容</span>
                </div>
                <Form :model="dtlRow" :label-width="120"  label-position="right" inline  >  
                    <span v-for="(item,index) in reqDtlCol" :key="index" >
                        <span v-if="item.gpno!=='38826'&& item.gpno!=='38610'" > 
                            <FormItem   :label="item.title"  :class="(item.key=='MATDESC'||item.key=='MATNM')?'item-width2':'item-width3'" v-if="item.fhide==='N'" :prop="item.key"  >                  
                                <span v-if="item.key=='MATTY'" >
                                    <el-cascader
                                        v-model="dtlRow['MATTY']"
                                        clearable
                                        placeholder="可通过关键字查找"
                                        filterable
                                        @change="mattyChg"
                                        style="width:100%"
                                        :options="prdType">
                                    </el-cascader>
                                </span>
                                <el-checkbox  border v-else-if="item.edcss==='checkbox'" true-label="Y"  false-label="N" v-model="dtlRow[item.key]" style="width:100%"></el-checkbox> 
                                <el-input v-else-if="item.key=='MATDESC'" type="textarea" :rows="6" readonly v-model="dtlRow[item.key]" ></el-input>
                                <el-input v-else type="text" :disabled="item.disabled==='Y'?true:false "  v-model="dtlRow[item.key]" ></el-input>
                            </FormItem>
                        </span>
                    </span>
                </Form> 
                <div style="display:flex;background:#E4E7ED;line-height:38px;height:38px;border-left:2px solid #409EFF; margin-bottom:2px;">
                    <span style="margin-left:10px;color:#303133; font-size:14px;">三、关联产品信息</span>
                </div>
                <Form :model="dtlRow" :label-width="120"  label-position="right" inline  >  
                    <span v-for="(item,index) in reqDtlCol" :key="index" >
                        <span v-if="item.gpno=='38826'" > 
                            <FormItem   :label="item.title"  :class="(item.key=='FEEDRMK' )?'item-width2':'item-width3'" v-if="item.fhide==='N'" :prop="item.key"  >                  
                                <div slot="label" v-if="item.key=='REFMATNO'"><el-link @click="matAdd(item)" style="color:#409eff">{{item.title}}</el-link></div>
                                <div slot="label" v-else-if="item.key=='REPLYPRC'||item.key=='FEEDRMK'"><span style="color:red">{{item.title}}</span></div>
                                <div v-if="item.key==='REFMATNO'" style="display:flex">
                                    <el-select @change="matChange"    v-model="dtlRow[item.key]" style="width:100%" filterable clearable>
                                        <el-row v-for="item in matArr" :key="item.IDSEQ" >                   
                                            <el-col :span="9">
                                                <el-option   :value="item.IDNO" :label="item.KRECOPN" ></el-option>
                                            </el-col>
                                            <el-col :span="7">
                                                <el-option  :value="item.IDNO" :label="item.MODELNM" ></el-option>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-option  :value="item.IDNO" :label="item.IDNO" ></el-option>
                                            </el-col>
                                        </el-row>                               
                                    </el-select>
                                    <el-button size="small" @click="qryMatInfo">查看</el-button>
                                </div>    
                                <el-checkbox  border v-else-if="item.edcss==='checkbox'" true-label="Y"  false-label="N" v-model="dtlRow[item.key]" style="width:100%"></el-checkbox> 
                                <el-input v-else type="text" :disabled="item.disabled==='Y'?true:false "  v-model="dtlRow[item.key]" ></el-input>
                            </FormItem>
                        </span>
                    </span>
                </Form>
                <div style="display:flex;background:#E4E7ED;line-height:38px;height:38px;border-left:2px solid #409EFF; margin-bottom:2px;">
                    <span style="margin-left:10px;color:#303133; font-size:14px;">四、选择供应商</span>
                    <el-link  :underline="false" style="margin-left:28px;color:blue;" @click="selSup"><i class="iconfont icon-xinzeng"></i> 添加</el-link>
                    <el-link  :underline="false" style="margin-left:28px;color:blue;" @click="impSup"><i class="iconfont icon-xiazai"></i> 导入历史报价</el-link>
                </div>
                <el-table  :data="supSel" 
                    border                            
                    size="small"                   
                    :height="300" 
                    highlight-current-row
                    style="overflow:auto;margin-top:1px;"> 
                    <span v-for="(item ) in reqSupCol" :key="item.key" > 
                        <el-table-column  v-if="item.thide==='N' &&item.key!=='MATNO' &&item.edcss!=='checkbox'
                         &&item.key!=='MATNM'&&item.key!=='MATDESC'&&item.key!=='MATTYNM'&&item.key!=='SUPPRC'&&item.key!=='RMK'"
                            :prop="item.key"
                            :label="item.title"
                            show-overflow-tooltip
                            :align="item.align"                                  
                            sortable
                            :min-width="item.width">
                        </el-table-column>
                        <el-table-column  v-if=" item.key=='SUPPRC'||item.key=='RMK'  "
                            :prop="item.key"
                            :label="item.title"
                            :align="item.align"                                  
                            :min-width="item.width">
                            <template slot-scope="scope">   
                                <span v-if="editFlag">{{scope.row[item.key]}}</span>
                                <el-input :id="scope.$index+ item.key " size="mini" style="width:100%" v-else  v-model="scope.row[item.key]"></el-input>
                            </template>
                        </el-table-column> 
                        <el-table-column  v-if ="item.edcss==='checkbox'"
                            :prop="item.key"
                            :label="item.title"
                            :align="item.align"                                  
                            :min-width="item.width">
                            <template  slot-scope="scope" >
                                <el-checkbox   disabled  true-label="Y"  false-label="N" v-model="scope.row[item.key]" ></el-checkbox>
                            </template>
                        </el-table-column> 
                    </span> 
                    <el-table-column
                        fixed="right"
                        label="操作"
                        align="center"
                        width="170">
                        <template slot-scope="scope">
                            <el-button size="mini"   type="text" icon="iconfont icon-chenggong" @click="selOk(scope.row ,scope.$index)">选中</el-button>
                            <el-button size="mini"   type="text" icon="iconfont icon-bianji" @click="editSup(scope.row ,scope.$index)">编辑</el-button>
                            <el-button size="mini"   type="text" icon="iconfont icon-shanchu1" @click="delSup(scope.row ,scope.$index)">删除</el-button>
                        </template>
                    </el-table-column> 
                </el-table>
                <div style=" height:35px;background:#f7f8f9;  ">
                    <span style="float:right">
                        <el-button  :disabled="disabled"  type="text" size="small"  @click="saveRec('mail')"   icon="iconfont icon-duanxin" style=" margin-right:20px;font-size:12px;">保存并发送邮件给供应商</el-button>
                    </span>
                </div>
                <div style="display:flex;background:#E4E7ED;line-height:38px;height:38px;border-left:2px solid #409EFF; margin-bottom:2px;margin-top:2px;">
                    <span style="margin-left:10px;color:#303133; font-size:14px;">五、销售报价回复</span>
                </div>
                <Form :model="dtlRow" :label-width="120"  label-position="right" inline  >  
                    <span v-for="(item,index) in reqDtlCol" :key="index" >
                        <span v-if="item.gpno=='38610'" > 
                            <FormItem   :label="item.title"  :class="(item.key=='FEEDRMK' )?'item-width2':'item-width3'"  :prop="item.key"  >                  
                               <el-input v-if="item.key=='FEEDRMK'" type="textarea" :rows="3"   v-model="dtlRow[item.key]" ></el-input>
                                <el-checkbox  border v-else-if="item.edcss==='checkbox'" true-label="Y"  false-label="N" v-model="dtlRow[item.key]" style="width:100%"></el-checkbox> 
                                <el-input-number v-else-if="item.key=='REPLYPRC'"  style="width:100%" v-model="dtlRow[item.key]" ></el-input-number>
                                <el-input v-else type="text" :disabled="item.disabled==='Y'?true:false "  v-model="dtlRow[item.key]" ></el-input>
                            </FormItem>
                        </span>
                    </span>
                </Form>
            </div>
            <div slot="footer">
                <el-button type="info" @click="editWin=false" size="small"  icon="iconfont icon-shanchu" style=" margin-right:30px;font-size:12px;">  取消</el-button>
                <el-button   type="primary" size="small"  @click="saveRec('save')"   icon="iconfont icon-baocun" style=" margin-right:20px;font-size:12px;">保存</el-button>
                <el-button  :disabled="disabled"  type="primary" size="small"  @click="saveRec('clnt')"   icon="iconfont icon-duanxin" style=" margin-right:5px;font-size:12px;">保存并发送邮件给客户</el-button>
            </div>
        </el-dialog>
        <!-- 提示框 -->
        <el-dialog :show-close="false" :visible.sync="promptWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:5px;">提示</span>
            </div>
            <div style=" margin:15px 10px">
                {{prompt}}
            </div>
            <div slot="footer">
                <Button type="primary"   @click="promptWin=false" style="margin-right:20px;">确定</Button>
            </div>
        </el-dialog>
        <!-- 选择供应商 -->
        <el-dialog :show-close="false" v-dialogDrag :visible.sync="supWin" width="550px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:5px;">选择供应商</span>
            </div>
            <div style=" margin:15px 10px">
                <el-select multiple filterable   v-model="prcsup" style="width:100%" >
                    <el-option  v-for="item in supArr" :key="item.IDNO" :value="item.IDNO" :label="item.SUPNM" ></el-option>
                </el-select> 
            </div>
            <div slot="footer">
                <el-button type="text" size="small"   @click="supWin=false"  icon="el-icon-close" style=" margin-right:45px;font-size:12px;">取消</el-button>           
                <el-button   type="primary" size="small"  @click="supCfm"   icon="iconfont icon-chenggong1" style=" margin-right:20px;font-size:12px;">确定</el-button>
            </div>
        </el-dialog>
        <el-dialog v-dialogDrag :visible.sync="showViewer" width="70vh" append-to-body>
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">预览</span>
            </div>
            <el-image fit="scale-down"  style=" margin:5px auto;height:500px;width:100% " :src="listurl"></el-image>
        </el-dialog>
        <!-- 新增产品 -->
        <el-dialog v-dialogDrag class="pub_dialog" :visible.sync="addWin" width="1100px" :show-close="false"   append-to-body :close-on-click-modal="false" :close-on-press-escape="false" top="4vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">新增产品</span>
            </div>
            <div style="height:90vh;overflow-y:auto">
                <matadd @child-exit="addExit"  @child-save="addSave" ref="refmat"></matadd>
            </div>
        </el-dialog>
        <!-- 历史报价 -->
        <el-dialog v-dialogDrag class="pub_dialog" :visible.sync="prcWin" width="1100px"   append-to-body :close-on-click-modal="false" :close-on-press-escape="false" top="4vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">历史报价</span>
            </div>
            <el-table  :data="prcData" 
                border                            
                size="small"                   
                :height="400" 
                highlight-current-row
                style="overflow:auto;margin-top:1px;"> 
                <span v-for="(item ) in prcArr" :key="item.key" > 
                    <el-table-column  v-if="item.thide==='N' &&item.edcss!=='checkbox'  "
                        :prop="item.key"
                        :label="item.title"
                        show-overflow-tooltip
                        :align="item.align"                                  
                        sortable
                        :min-width="item.width">
                    </el-table-column>
                    <el-table-column  v-if ="item.edcss==='checkbox'"
                        :prop="item.key"
                        :label="item.title"
                        :align="item.align"                                  
                        :min-width="item.width">
                        <template   slot-scope="scope" >
                            <el-checkbox    true-label="Y"  false-label="N" v-model="scope.row[item.key]" ></el-checkbox>
                        </template>
                    </el-table-column> 
                </span> 
            </el-table>
            <div style="line-height:35px; float:right;">
                <el-link @click="prcCfm" :underline="false" style="margin-right:20px; "><i class="iconfont icon-chenggong" style="margin-right:5px;"></i>确定</el-link>
                <el-link :underline="false" @click="prcWin=false"  ><i class="iconfont icon-shanchu" style="margin-right:5px;"></i>取消</el-link>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {getBaseData,getTabColOrData} from '../../api/user'; 
import { paramData2,paramData,countryArray ,paramDataOrder } from '../../api/Select' 
import matadd from '../datamgt/matadd_tmpl.vue'
export default {
    name:'clnt_askprc',
    props: {
        v_cond:{type:String,default:''}  
    },
    data () {
        return {
            prcWin:false,
            prcData:[],
            prcsup:'', //报价供应商
            supWin:false,
            addWin:false,
            promptWin:false,
            showViewer:false,
            editFlag:true,
            listurl:'',
            prompt:'',
            v_idseq:'-1',
            v_lstseq:'-1',
            attArr:[],
            prdType:[],
            disabled:false,
            cpWin:[],
            supArr:[],
            prcArr:[],
            cpRow:{},
            matRow:{},
            mainRow:{},
            dtlRow:{},
            editWin:false, 
            goPage:'',
            curind:-1,
            reqSupCol:[],
            supData:[],
            reqData:[],
            dtlData:[],
            matArr:[],
            loading:false,
            current:1,
            supSel:[],
            allList:[], //询价单集合
            v_username :this.$cookies.get('v_username' ), //用户名称 
            v_password:this.$cookies.get('v_password') ,//api 请求密码
            v_url:this.$store.state.queryUrl , //api请求路径   
            v_matid:'',
            condition:{
                idno:'', //询价单 号
                mattynm:'', //询价品类
                telno:'', //电话号码
                stfg:'wait', //订单状态
                dttm:'', //下单日期
                todttm:'', //结束下单日期
                matnm:'', //询价品名
            },
            options: [{
                value: 10,
                label: '10'
                }, {
                value: 20,
                label: '20'
                }, {
                value: 50,
                label: '50'
            }],
            v_index:'',
            dataCount:0,
            rowSize:10,
            reqDtlCol:[],
            reqCol:[],
            prcTerm:[],
            chnnPrc:[],
            payTerm:[],
            pkgUnit:[],
            countryarea:[],
            //日期选项
            pickerOptions: {
                // disabledDate(time) {
                //     return time.getTime() > Date.now();
                // },
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        picker.$emit('pick', new Date());
                    }
                }, 
                {
                    text: '昨天',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24);
                        picker.$emit('pick', date);
                    }
                }, 
                {
                    text: '一周前',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', date);
                    }
                }]
            },
        }
    },
    components: {
      matadd,  
    },
    computed: {
        tabHeight: function() {
            return (window.innerHeight - 745) + 'px';
        },
        subHeight: function() {
            return (window.innerHeight - 480) + 'px';
        }, 
        tabWidth: function() {
            return (window.innerWidth - 140) + 'px';
        },
    },
    watch: {
        v_cond :function(){
            this.getReqList(this.v_cond)
        }
    },
    created () {
        if (this.v_username){
            this.getTabCol() //表列集合
            this.getReqList(this.v_cond+ ' and stfg=\''+this.condition.stfg+'\'') //询价单主数据
        }else{
            let routeUrl = this.$router.resolve({
                path: '/',
                query: {lang:this.LANG },
            });
            window.open(routeUrl.href, '_self'); 
        }
    },
    mounted () {
        //产品类目 
        getBaseData(this.$store.state.nologinCasUrl, 'parentno','0','','','V_PRD_CATEGORY3','').then(res=>{
            this.prdType=res.data
        })
        //贸易条款
        paramData('PARENTNO','19667' ,'V_SYS_BASE').then((res)=>{               
            this.prcTerm =res.data.result
        });  
        //渠道商价格体系
        paramData('PARENTNO','19799' ,'V_SYS_BASE').then((res)=>{               
            this.chnnPrc =res.data.result
        }); 
        paramData('PARENTNO','38932' ,'V_SYS_BASE').then((res)=>{               
            this.payTerm =res.data.result
        }); 
        paramData('PARENTNO','19688' ,'V_SYS_BASE').then((res)=>{               
            this.pkgUnit =res.data.result
        });
        //国家
        countryArray().then((res)=>{
            this.countryarea =res.data
        });
    },
    methods: {
        //选中供应商
        selOk(row,index){
            this.supSel.forEach((item,ind)=>{
                if (item['SUPNO']==row['SUPNO']){
                    this.supSel.splice(index,1,Object.assign(row,{ISOK:'Y'}))
                    let gross=!this.dtlRow['GROSS']?0:this.dtlRow['GROSS']
                    let discnt=!this.dtlRow['DISCNT']?0:this.dtlRow['DISCNT']
                    this.dtlRow['REPLYPRC']=(Number(row['SUPPRC']) *(100+Number(gross)-Number(discnt))/100).toFixed(2)
                }else{
                   this.supSel.splice(ind ,1,Object.assign(item,{ISOK:'N'})) 
                }
            })
        },
        //导入历史供应商报价
        impSup(){
            this.prcWin =true
            this.prcData =[]
            paramDataOrder ('matno',this.dtlRow['REFMATNO'],'V_PRC_REQ_SUP',' order by prcdttm desc').then((res)=>{               
                this.prcData =res.data.result
            })
        },
        mattyChg(val){
            let val_ =val.join(',').substring(0,val.join(',').lastIndexOf(',') )
            let code_ =val.join(',').substring( val.join(',').lastIndexOf(',')+1 )
 
            //物料清单
            paramData ('matty',val_,'V_BS_MAT').then((res)=>{               
                this.matArr =res.data.result
                Object.assign(this.dtlRow,{'REFMATNO':''})   
            })
            
            
        },
        //选择供应商确定
        supCfm(){
            let prcsup_ =this.prcsup
            let supnm_='',empnm_='',mail_=''
            for (let k=0;k<prcsup_.length;k++){
                this.supArr.some(item=>{
                    if (item['IDNO']==prcsup_[k]){
                        supnm_=item['SUPNM']
                        empnm_=item['EMPNM']
                        mail_=item['MAILADDR']
                    }
                })
                let obj ={'IDSEQ':this.v_idseq,'LSTSEQ':this.v_lstseq,'ITMSEQ':'','SUPNO':prcsup_[k],'SUPNM':supnm_,'EMPNM':empnm_,'ISOK':'N',MAILADDR:mail_}
                this.supSel.push(obj)
            }           
            this.supWin=false
        },
        //历史报价选择
        prcCfm(){
            let bool =true
            let okData=[]
            this.prcData.forEach(item=>{
                if (item['SEL']=='Y'){
                    okData.push(item)
                }
            })
            if (okData.length==0){
                this.promptWin=true
                this.prompt='请选择行'
            }else{
                for (let k=0;k<okData.length;k++){
                    let obj ={'IDSEQ':this.v_idseq,'LSTSEQ':this.v_lstseq,'ITMSEQ':'','SUPNO':okData[k]['SUPNO'],'SUPPRC':okData[k]['SUPPRC'],
                          'SUPNM':okData[k]['SUPNM'],'EMPNM':okData[k]['EMPNM'],'ISOK':'N',MAILADDR:okData[k]['MAILADDR'],RMK:okData[k]['RMK']}
                    this.supSel.push(obj)
                }
                this.prcWin=false
            }  
        },
        selSup(){
            this.supWin=true
            this.prcsup=''
            paramDataOrder('','','V_BS_sup',' and instr(PREVprdty,\''+this.v_matid+'\')>0').then((res)=>{               
                this.supArr =res.data.result   
            })
        },
        editSup(row,index){
            this.editFlag=false
        },
        delSup(row,index){
            this.$confirm('你确定要删除此行记录吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                var vm=this;
                this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/delTableRec?username='+this.v_username +'&password='+this.v_password,
                    data: {p_key:'idseq',p_value:row.IDSEQ,p_key2:'lstseq',p_value2:row.LSTSEQ,p_key3:'itmseq',p_value3:row.ITMSEQ, p_table:'PRC_REQ_sup' },
                }).then(res=>{
                        this.supSel.splice(index,1)
                })
            }).catch(error=>{

            })
        },
        matChange(item){
            this.$forceUpdate()
            paramData ('idno',item,'V_BS_MAT').then((res)=>{               
                Object.assign(this.dtlRow,{'REPLYPRC':res.data.result[0]['TAXPRC']}) 
                Object.assign(this.dtlRow,{'REFMODEL':res.data.result[0]['MODELNM']})   
                //优惠率
                paramData ('code',res.data.result[0]['MODELNO'],'V_PRDTYPE_MGT').then((res)=>{ 
                    Object.assign(this.dtlRow,{'GROSS':res.data.result[0]['GROSSPCT'],'DISCNT':res.data.result[0]['DISPCT']})   
                })
            })
        },
        //产品保存时，得到产品序号刷新下拉框 并回调此列
        addSave(idseq){
            this.addWin=false
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'auth/login/getBaseData' ,
                data: {p_key:'matty',p_value:this.dtlRow['MATTY'] ,p_table:'V_BS_MAT'},
            }).then(res=>{  
                this.matArr =res.data.result
                paramData('idseq',idseq ,'V_mat_valid').then((res)=>{               
                    this.dtlRow['REFMATNO'] =res.data.result[0]['IDNO']
                    this.$forceUpdate()
                })
            })
        },
        addExit(){
            this.addWin=false
        },
        //新增产品
        matAdd(row){
            this.addWin=true
            this.$nextTick(()=>{
                this.$refs.refmat.addRec(this.dtlRow)
            })          
        },
        qryMatInfo(){
            if (this.dtlRow['REFMATNO'] ){
                this.addWin=true
                paramData('idno',this.dtlRow['REFMATNO'] ,'V_MAT_VALID').then((res)=>{    
                    this.$nextTick(()=>{    
                       // console.log('row:'+JSON.stringify(res.data.result[0]))       
                        this.$refs.refmat.qryRec( res.data.result[0] )
                    })
                }) 
            }else{
                this.promptWin=true
                this.prompt ='关联PN不能为空'
            }
        },
        //删除记录
        delRec(row ,index ){
            this.$confirm('你确定要删除此行记录吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                var vm=this;
                this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/delTableRec?username='+this.v_username +'&password='+this.v_password,
                    data: {p_key:'idseq',p_value:row.IDSEQ,p_key2:'lstseq',p_value2:row.LSTSEQ, p_table:'PRC_REQDTL' },
                }).then(res=>{
                    if (res.data.code=='200'){
                        this.getDtlList(this.v_idseq )
                    }else{
                        this.promptWin=true
                        this.prompt='存在子记录，不能删除'
                    }
                })
            }).catch(()=>{})
        },
        //复制记录
        cpRec(row ,index ){
            this.$confirm('你确定要复制此行记录吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                var vm=this;
                this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/tableBodySave?username='+this.v_username +'&password='+this.v_password,
                    data: {p_json:JSON.stringify(Object.assign(row,{LSTSEQ:'','ISCP':'Y'})), p_table:'PRC_REQDTL',p_idseq: this.v_idseq},
                }).then(res=>{
                    this.getDtlList(this.v_idseq )
                })
            }).catch(()=>{})
        },
        //预览PDF
        pdfViewer(filePath){
            let routeUrl = this.$router.resolve({
                path: '/fullPreview',
                query: { fileUrl:filePath},
            });
            window.open(routeUrl.href, '_blank');
        },
        //图片预览
        imgPreview(path){
            this.showViewer=true
            this.listurl=path
        },
        //保存修改记录
        saveRec(val){
            let v_bool =true
            let mailaddr=''
            if (val=='mail'){
                this.supSel.forEach(item=>{
                    if (!item['MAILADDR']){
                        v_bool =false
                    }
                })
            }
            if(this.supSel.length==0 &&val=='mail'){
                this.promptWin=true
                this.prompt='请选择供应商'
            }else if(!v_bool &&val=='mail'){
                this.promptWin=true
                this.prompt='供应商的邮箱地址不能为空，请核查'
            }else if(!this.dtlRow['REFMATNO'] &&val=='mail'){
                this.promptWin=true
                this.prompt='关联景荣PN不能为空，请核查'
            }else if(!this.matRow['MAILADDR'] &&val=='clnt'){
                this.promptWin=true
                this.prompt='客户的询价邮箱地址不能为空，请核查'
            }else if(!this.dtlRow['REPLYPRC'] &&val=='clnt'){
                this.promptWin=true
                this.prompt='销售报价单价不能为空，请核查'
            }else if(!this.dtlRow['FEEDRMK'] &&val=='clnt'){
                this.promptWin=true
                this.prompt='销售报价回复不能为空，请核查'
            }else{
                var vm=this;
                this.$axios({  //传入 p_frmid 参数是为了生成 表单 的编码
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/formOneSave?username='+vm.v_username+'&password='+vm.v_password,
                    data: {p_json:JSON.stringify(this.matRow), p_table:'PRC_REQ',frmid:''},
                }).then(res=>{ 
                    //询价明细保存
                    let req_='N',reply_='N'
                    if (this.dtlRow['ISREQ']=='Y'){
                        req_='Y'
                    }else{
                        req_=val=='mail'?'Y':'N'
                    }
                    if (this.dtlRow['ISREPLY']=='Y'){
                        reply_='Y'
                    }else{
                        reply_=val=='clnt'?'Y':'N'
                    }
                    let matty_=this.dtlRow['MATTY'] ,v_matty =''
                    if(typeof(matty_)==='object'){
                        v_matty=matty_.join(',')
                    }else{
                        v_matty=matty_
                    }
                    this.$axios({ 
                        method: 'post',
                        url:vm.$store.state.baseUrl+'sysprivs/tableBodySave?username='+vm.v_username+'&password='+vm.v_password,
                        data: {p_json:JSON.stringify(Object.assign(this.dtlRow,{'ISREQ':req_,'ISREPLY':reply_,MATTY: v_matty})),p_idseq:this.v_idseq, p_table:'prc_reqdtl' },
                    }).then(res=>{      
                        if(res.data.code =='200')  {  
                            //更新产品库的 销售单价
                            this.$axios({
                                method: 'post',
                                url:vm.$store.state.baseUrl+'user/updateRec?username='+vm.v_username+'&password='+vm.v_password,
                                data: {p_key:'idno',p_value:this.dtlRow['REFMATNO'],p_table:'bs_mat',p_cond:' set TAXPRC='+this.dtlRow['REPLYPRC'] },
                            })          
                            //保存选择的供应商
                            for (let k=0;k<this.supSel.length; k++) {
                                if (mailaddr){
                                    mailaddr =mailaddr+';'+this.supSel[k]['MAILADDR']
                                }else{
                                    mailaddr = this.supSel[k]['MAILADDR']
                                }
                                this.$axios({
                                    method: 'post',
                                    url:vm.$store.state.baseUrl+'sysprivs/tableBodySave2?username='+vm.v_username+'&password='+vm.v_password,
                                    data: {p_json:JSON.stringify( this.supSel[k]  ), p_table:'prc_req_sup' },
                                }).then(res=>{  
                                    if (res.data.code!=='200'){
                                        this.promptWin =true
                                        this.prompt=res.data.result
                                    }
                                })
                            }
                            //发送邮件给供应商
                            if (mailaddr && val=='mail'){
                                let note_=''
                                getTabColOrData(this.v_url,this.v_username,this.v_password,'v_CONTENT_SET','idseq','57228','','','','','' ).then((res) => { 
                                    note_=res.data[0]['NOTE'] +this.matRow['IDNO']
                                    this.$axios({
                                        method: 'post',
                                        url:vm.$store.state.baseUrl+'user/sendReqMail?username='+vm.v_username+'&password='+vm.v_password,
                                        data: {'mailaddr':mailaddr,'title':res.data[0]['TITLE'], p_note:note_},
                                    }).then(res=>{
                                        if (res.data.code!=='200'){
                                            this.promptWin=true
                                            this.prompt=res.data.result
                                        } 
                                    })
                                })
                            }
                            //发送邮件给客户
                            if (this.matRow['MAILADDR'] && val=='clnt'){
                                let note_=''
                                getTabColOrData(this.v_url,this.v_username,this.v_password,'v_CONTENT_SET','idseq','57229','','','','','' ).then((res) => { 
                                    note_=res.data[0]['ENOTE'] +this.matRow['IDNO']
                                    this.$axios({
                                        method: 'post',
                                        url:vm.$store.state.baseUrl+'user/sendReqMail?username='+vm.v_username+'&password='+vm.v_password,
                                        data: {'mailaddr':this.matRow['MAILADDR'],'title':res.data[0]['ETITLE'], p_note:note_},
                                    }).then(res=>{
                                        if (res.data.code!=='200'){
                                            this.promptWin=true
                                            this.prompt=res.data.result
                                        } 
                                    })
                                })
                            }
                            this.dtlData.splice(this.v_index,1,Object.assign(this.dtlRow,{'MATTY':matty_}))
                            vm.editWin=false
                        }else{
                            this.promptWin =true
                            this.prompt=res.data.result
                        }        
                    })
                })
            }
        },
        //编辑
        editRec(row,index){
            this.v_index =index
            if (row.ISREQ=='Y'&&row.ISREPLY=='Y'){
                this.disabled=true
            }else{
                this.disabled=false
            }
            this.editWin=true
            this.v_idseq=row['IDSEQ']
            this.v_lstseq=row['LSTSEQ']
            this.v_matid=row['MATID']  
            this.matRow=this.mainRow 
 
            if (typeof(row['MATTY'])=='string'){
                this.dtlRow= JSON.parse(JSON.stringify(Object.assign(row,{'MATTY':row['MATTY'].split(',')})))
            }else{
                this.dtlRow= JSON.parse(JSON.stringify(row))
            }
            this.supArr=[]
            paramData('matty',row['MATTY'][0]+','+ row['MATTY'][1],'V_BS_MAT').then((res)=>{               
                this.matArr =res.data.result   
            })                   
            //选择的供应商
            this.supSel =[]
            paramData2('idseq',this.v_idseq,'lstseq',this.v_lstseq ,'V_PRC_REQ_SUP').then((res)=>{               
                this.supSel =res.data.result
            })
            this.editFlag=true
        },
        getRowKey(row){
            return row.IDSEQ
        },
        getRowKey2(row){
            return row.IDSEQ+'-'+row.LSTSEQ
        },
        //状态变更
        stfgRec(row,val){
            let v_comm=''
            if (val==='cancel'){
                v_comm='作废'
            }else if (val==='ok'){
                v_comm='已完成'
            }else if (val==='wait'){
                v_comm='报价中'
            }else if (val==='part'){
                v_comm='部份报价'
            }
            this.$confirm('你确定要变更为'+v_comm+'吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                var vm=this;
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'user/updateRec?username='+vm.v_username+'&password='+vm.v_password,
                    data: {p_key:'idseq',p_value:row.IDSEQ,p_table:'prc_req',p_cond:' set stfg=\''+val+'\' '},
                }).then(res=>{      
                    if(res.data.code =='200')  {           
                        Object.assign(row,{STFG:val })
                        this.getDtlList(row.IDSEQ)
                        this.$emit('child-oper')
                    }else{
                        this.promptWin =true
                        this.prompt=res.data.result
                    }        
                })
            }).catch(()=>{})
             
        },
        // 行点击事件
        rowClick(row){
             this.v_idseq =row.IDSEQ
             this.getDtlList(row.IDSEQ)
             this.mainRow=JSON.parse(JSON.stringify(row))        
        },
        dtlClick(row){
            this.v_lstseq=row.LSTSEQ
            //附件
            paramData2('idseq',row.IDSEQ,'lstseq',row.LSTSEQ ,'prc_reqfile').then((res)=>{               
                this.attArr =res.data.result
            });
            //供应商
            paramData2('idseq',row.IDSEQ,'lstseq',row.LSTSEQ ,'V_PRC_REQ_SUP').then((res)=>{               
                this.supData =res.data.result
            });
        },
        //重置
        btnClear() {
            this.condition={stfg:'wait'}
        },
        //菜单事件
        btnQry(){ 
            let v_cond=this.v_cond
            if (this.condition.idno){ //订单编码
                v_cond =v_cond +' and idno like \'%'+this.condition.idno+'%\''
            }
            if (this.condition.stfg!=='all'){ //状态
                v_cond =v_cond +' and stfg = \''+this.condition.stfg+'\''
            }
            if (this.condition.dttm){ //下单日期
                v_cond =v_cond +' and adddttm>=\''+this.condition.dttm+'\''
            }
            if (this.condition.todttm){ //下单日期
                v_cond =v_cond +' and adddttm<=\''+this.condition.todttm+'\''
            }
            if (this.condition.matnm){ //询价品名
                v_cond =v_cond +' and idseq in (select idseq from prc_reqdtl where matnm like \'%'+this.condition.matnm+'%\')' 
            }
            if (this.condition.mattynm){ //询价品类
                v_cond =v_cond +' and idseq in (select idseq from prc_reqdtl where mattynm like \'%'+this.condition.mattynm+'%\')' 
            }
 
            this.getReqList(v_cond)
 
        },
        goNext(){
            if (this.goPage){
                this.changePage(this.goPage)
                this.current =Number(this.goPage)
            }else{
                this.$alert('请输入跳至页数', '提示', {
                    confirmButtonText: '确定',
                    showClose:false,
                    type:'info'
                })
            }
        },
        //表格页面导航
        changePage(index){
            var _start = ( index - 1 ) * this.rowSize;
            var _end = index * this.rowSize;
            this.reqData = this.allList.slice(_start,_end);
        },
        //初始化查询页条数
        initPage(){
            //产品明细
            this.dataCount =this.allList.length //总条数
            // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
            if (this.allList.length < this.rowSize){
                this.reqData = this.allList          
            }else{
                this.reqData =this.allList.slice(0,this.rowSize) //返回选定元素的子数组，不包含结尾元素
            }
            this.$nextTick(() => { //有固定列时 查询后重新布局
                this.$refs.refreq.doLayout();
            }) 
        },
 
        //询价单 
        getReqList(cond){
            let vm =this
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_prc_req','','','','','','',cond+' order by adddttm desc' ).then((res) => { 
                this.reqData =res.data 
                this.$nextTick(() => { //有固定列时 查询后重新布局
                    this.$refs.refreq.doLayout();
                })
                this.attArr=[]
                this.supData=[]
                if (res.data.length>0){
                    if (this.v_idseq!=='-1'){
                        this.getDtlList(this.v_idseq)
                    }else{
                        this.getDtlList(res.data[0].IDSEQ)
                        this.mainRow=res.data[0]   
                        this.v_idseq =res.data[0].IDSEQ
                        this.$refs.refreq.setCurrentRow(res.data[0])    
                    }             
                }else{
                    this.dtlData=[]
                }
            })
        },
        //询价单明细
        getDtlList(idseq){
            let vm =this
            this.attArr=[]
            this.supData=[]
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_prc_reqdtl','idseq',idseq,'','','','',' order by adddttm' ).then((res) => { 
                this.dtlData =res.data 
                if (res.data.length>0){
                    if (this.v_lstseq=='-1'){
                        //附件
                        paramData2('idseq',idseq,'lstseq',res.data[0].LSTSEQ ,'prc_reqfile').then((res)=>{               
                            this.attArr =res.data.result
                        });
                        //供应商
                        paramData2('idseq',idseq,'lstseq',res.data[0].LSTSEQ ,'V_PRC_REQ_SUP').then((res)=>{               
                            this.supData =res.data.result
                        });
                        this.$refs.refdtl.setCurrentRow(res.data[0])  
                    }else{
                        //附件
                        paramData2('idseq',idseq,'lstseq',this.v_lstseq,'prc_reqfile').then((res)=>{               
                            this.attArr =res.data.result
                        });
                        //供应商
                        paramData2('idseq',idseq,'lstseq',this.v_lstseq ,'V_PRC_REQ_SUP').then((res)=>{               
                            this.supData =res.data.result
                        });
                    }
                }
            })
        },
        //获取表列头字段  v-enter-number
        getTabCol(){
            var vm=this
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','114','fty','HEAD','','',' order by sortby,lstseq' ).then((res) => {
                for (let k=0; k<res.data.length; k++){
                    vm.reqCol.push({ //向数组的开头 添加序列号
                        title: res.data[k].title,
                        key: res.data[k].key,
                        align: res.data[k].align,
                        width: res.data[k].width,  
                        thide: res.data[k].THIDE,     
                        fhide: res.data[k].FHIDE,                   
                        edcss: res.data[k].EDCSS, 
                        disabled: res.data[k].DISABLED, 
                    })
                }
            })
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','114','fty','HEAD2','','',' order by sortby,lstseq' ).then((res) => {
                for (let k=0; k<res.data.length; k++){
                    vm.reqDtlCol.push({ //向数组的开头 添加序列号
                        title: res.data[k].title,
                        key: res.data[k].key,
                        align: res.data[k].align,
                        width: res.data[k].width,  
                        gpno:res.data[k].GPNO, 
                        thide: res.data[k].THIDE,  
                        fhide: res.data[k].FHIDE,    
                        edcss: res.data[k].EDCSS,                   
                        disabled: res.data[k].DISABLED, 
                    })
                }
            })
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','114','fty','HEAD4','','',' order by sortby,lstseq' ).then((res) => {
                for (let k=0; k<res.data.length; k++){
                    vm.prcArr.push({ //向数组的开头 添加序列号
                        title: res.data[k].title,
                        key: res.data[k].key,
                        align: res.data[k].align,
                        width: res.data[k].width,  
                        gpno:res.data[k].GPNO, 
                        thide: res.data[k].THIDE,  
                        fhide: res.data[k].FHIDE,    
                        edcss: res.data[k].EDCSS,                   
                        disabled: res.data[k].DISABLED, 
                    })
                }
            })
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','114','fty','HEAD3','','',' order by sortby,lstseq' ).then((res) => {
                for (let k=0; k<res.data.length; k++){
                    vm.reqSupCol.push({ //向数组的开头 添加序列号
                        title: res.data[k].title,
                        key: res.data[k].key,
                        align: res.data[k].align,
                        width: res.data[k].width,  
                        thide: res.data[k].THIDE,  
                        fhide: res.data[k].FHIDE,                      
                        edcss: res.data[k].EDCSS,   
                        disabled: res.data[k].DISABLED, 
                    })
                }
            })
        },
    }
}
</script>
<style scoped lang="less">
    ::v-deep .el-table {
        th {
            padding: 0px ;
        }
        thead{
            height: 15px !important;
            color:rgb(71, 70, 70);
            font-size:12px;
        }
        td {
            padding: 3px ;
            font-size:11px;
        }   
    }
    .item-width  {
        width: 48%;
        color:#409EFF;
    }
    .item-width2  {
        width: 97%;
        color:#409EFF;
    }
    .item-width3  {
        width: 32%;
        //background: #ccc;
    }
    .pub_dialog {
        display: flex;
        justify-content: center;
        align-items: Center;
        overflow: hidden;
        .el-dialog {
            margin: 0 auto !important;
            height: 90%;
            overflow: hidden;
            .el-dialog__body {
                position: absolute;
                left: 0;
                top: 54px;
                bottom: 0;
                right: 0;
                padding: 0;
                z-index: 1;
                overflow: hidden;
                overflow-y: auto;
            }
        }
    }
</style>
<style lang="less">
 
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
    //.el-dialog__headerbtn i { background:white;font-size: 20px;  }
    
</style>