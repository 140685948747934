<template>
  <div  >  
    <div >
    <el-card v-for="(gpitem,index) in tabFrmCol" :key="index" style="margin-bottom:15px;">
        <p slot="header" style="color:#5cadff;height:14px;line-height:14px ">
            <span style="font-weight:600;font-size:14px;color:#5cadff">{{gpitem.gpnm}} </span> 
            <el-link :disabled="!saveFlag?true:false" v-if="gpitem.gpno==='38753'" :underline="false" @click="mgtParam">【管理参数及属性值<i class="iconfont icon-xiashuangjiantou" style="color:red"></i>】</el-link> 
            <el-link v-if="gpitem.gpno==='38815'" :underline="false" @click="videoRmk">【查看说明】</el-link>    
            <el-link v-if="gpitem.gpno==='38752'" :underline="false" >【提示：最多只能上传3张图片】</el-link>         
        </p>
        <!-- 产品参数:38753,产品图片:38752  -->
        <span v-if="gpitem.gpno!=='38753' && gpitem.gpno!=='38752'">
        <Form :model="matRow" :label-width="120" ref="refform"  label-position="right" inline  :rules="ruleValidate">  
            <span v-for="(item,index) in gpitem.gplist" :key="index" >
                <FormItem   :label="item.FLDNM"  class="item-width" v-if="item.FHIDE==='N'" :prop="item.FLDNO"  >                  
                    <el-date-picker :disabled="!saveFlag?true:false" type="date"  v-if="item.EDCSS==='date'" v-model="matRow[item.FLDNO]" clearable value-format="yyyy-MM-dd" style="width:100%" ></el-date-picker>   
                    <el-input-number :disabled="!saveFlag?true:false||item.DISABLED==='Y'?true:false" :precision="item.DOTNUM"  v-else-if="item.EDCSS==='number'" v-model="matRow[item.FLDNO]" style="width:100%" ></el-input-number>   
                    <el-select  :disabled="!saveFlag?true:false" v-else-if="item.FLDNO==='MATPROP'" v-model="matRow[item.FLDNO]" style="width:100%" >
                        <el-option v-for="it in propArr" :key="it.CNAME" :value="it.CNAME" :label="it.CNAME" >
                        </el-option>
                    </el-select> 
                    <el-select :disabled="!saveFlag?true:false"  @change="modelChange" v-else-if="item.FLDNO==='MODELNO'" v-model="matRow[item.FLDNO]" clearable filterable style="width:100%"  >
                        <el-option v-for="(item,index) in modelArr" :key="index" :value="item.SUBNO" :label="item.CNAME" ></el-option>
                    </el-select> 
                    <el-select :disabled="!saveFlag?true:false" v-else-if="item.FLDNO==='SHPDTTM'" v-model="matRow[item.FLDNO]"  style="width:100%"  >
                        <el-option v-for="(item,index) in shpArr" :key="index" :value="item.CNAME" :label="item.CNAME" ></el-option>
                    </el-select> 
                    <el-select multiple :disabled="!saveFlag?true:false" v-else-if="item.FLDNO==='APPFIELD'" v-model="matRow[item.FLDNO]"  style="width:100%"  >
                        <el-option v-for="(item,index) in appArr" :key="index" :value="item.CODE" :label="item.CNAME" ></el-option>
                    </el-select> 
                    <el-select multiple :disabled="!saveFlag?true:false" v-else-if="item.FLDNO==='PRDCERT'" v-model="matRow[item.FLDNO]"  style="width:100%" filterable >
                        <el-option v-for="(item,index) in certArr" :key="index" :value="item.CODE" :label="item.CNAME" ></el-option>
                    </el-select> 
                    <el-select  :disabled="!saveFlag?true:false" v-else-if="item.FLDNO==='STFG'" v-model="matRow[item.FLDNO]" style="width:100%">
                        <el-option v-for="item in prdonArr" :key="item.CNAME" :value="item.CNAME" :label="item.CNAME" ></el-option>
                    </el-select>
                    <el-select :disabled="!saveFlag?true:false"  v-else-if="item.FLDNO==='SUPNO'"  v-model="matRow[item.FLDNO]" style="width:100%" filterable clearable>
                        <el-option v-for="item in supArr" :key="item.IDNO" :value="item.IDNO" :label="item.SUPNM" ></el-option>
                    </el-select>
                    <el-input :disabled="!saveFlag?true:false" v-enter-number v-else-if="item.FLDNO==='SZWIDTH'"  v-model="matRow[item.FLDNO]" placeholder="宽">
                        <span slot="prepend">长</span>
                        <el-input v-enter-number v-model="matRow['SZLONG']" slot="prepend" placeholder="长" style="width:100px;"></el-input>
                        <span slot="prepend">宽</span>
                        <span slot="append" style="text-align:center">高</span>
                        <el-input v-enter-number v-model="matRow['SZHIGH']" slot="append" placeholder="高" style="width:100px;"></el-input>  
                    </el-input>
                    <el-input :disabled="!saveFlag?true:false" v-enter-number v-else-if="item.FLDNO==='BOXW'"  v-model="matRow[item.FLDNO]" placeholder="宽">
                        <span slot="prepend">长</span>
                        <el-input v-enter-number v-model="matRow['BOXL']" slot="prepend" placeholder="长" style="width:100px;"></el-input>
                        <span slot="prepend">宽</span>
                        <span slot="append" style="text-align:center">高</span>
                        <el-input v-enter-number v-model="matRow['BOXH']" slot="append" placeholder="高" style="width:100px;"></el-input>  
                    </el-input> 
                    <el-input disabled v-else-if="item.FLDNO==='BOXCUBE'"  v-model="BOXCUBE" ></el-input>
                    <el-cascader
                        v-model="matRow[item.FLDNO]"
                        clearable
                        placeholder="可通过关键字查找"
                        ref="prdCas"
                        :disabled="!saveFlag?true:false"
                        v-else-if="item.FLDNO==='MATTY'"
                        filterable
                        @change="casChange"
                        style="width:100%;font-size:12px;"
                        :options="prdType">
                    </el-cascader>
                    <el-input disabled type="text" placeholder="系统自动生成"  v-else-if="item.FLDNO==='IDNO'"   v-model="matRow[item.FLDNO]"></el-input>
                    <el-input :disabled="!saveFlag?true:false"  :readonly="item.DISABLED==='Y'?true:false" type="textarea" :rows="item.ROWNUMS" placeholder="请输入内容" v-else-if="item.FLDNO==='BUYZH'"   v-model="matRow[item.FLDNO]"></el-input>
                    <el-input disabled  type="textarea" :rows="item.ROWNUMS" placeholder="根据属性/值自动拼接内容" v-else-if="item.FLDNO==='MATDESC' "   v-model="matRow[item.FLDNO]"></el-input>
                    <el-input   type="text" v-else :disabled="item.DISABLED==='Y'?true:false||!saveFlag "  v-model="matRow[item.FLDNO]" ></el-input>
                </Formitem>     
            </span>
        </Form>
        </span>
        <!-- 产品参数:38753  -->
        <span v-if="gpitem.gpno==='38753'"> 
            <Form :model="paramRow" :label-width="120" ref="paramWin"  label-position="right" inline >  
                <span v-for="(item,index) in paramArr" :key="index" >
                    <FormItem  :label="item.cname"  class="item-width" :prop="item.code"  >
                        <el-input :disabled="!saveFlag?true:false" v-if="item.ishand==='Y'" type="text"   v-model="paramRow[item.lstseq]" style="width:100%"></el-input>
                        <el-select :disabled="!saveFlag?true:false" v-else @change="modelChange" v-model="paramRow[item.lstseq]"  style="width:100%" filterable>
                            <el-option v-for="(it,ind) in item.children" :key="ind" :value="it.code" :label="it.cname" ></el-option>
                        </el-select> 
                    </FormItem>
                </span>
            </Form>
        </span>
        <!-- 产品图片:38752  -->
        <span v-if="gpitem.gpno==='38752'"> 
            <div style="display:flex" >
                <div style="display:flex; "  >
                    <div v-for="(item,index) in videoData" :key="index" style="border:1px solid #ccc;width:152px;">  
                        <el-image fit="scale-down"  style="width:120px;height:120px;margin-left:15px;" :src="item.FILEPATH"></el-image>
                        <div style="height:25px;background:#e8eaec;width:150px; display:flex;justify-content:space-between">
                            <el-link :disabled="!saveFlag?true:false" :underline="false" @click="imgDel(item.IDSEQ,item.LSTSEQ,item.FILEPATH,index)"><Icon type="md-close" /> 删除</el-link>
                            <el-link :underline="false" @click="imgPreview(item.FILEPATH)"><Icon type="ios-search" /> 预览</el-link>
                        </div>
                    </div>
                </div> 
                <div v-if="videoData.length<3" style="margin-left:15px;width:140px;height:152px;border:1px dashed #ccc">
                    <el-link :underline="false"  >
                        <Upload
                            ref="upload"
                            name="upfile"
                            :data="uploadData"
                            :show-upload-list="false"
                            :before-upload="beforeImgFile"
                            :on-success="successImgFile"
                            :on-progress="handleProgress"
                            accept="png,jpg,jpeg"   
                            :disabled="!saveFlag"                      
                            :format="['jpg','jpeg','png','gif','webp' ]"
                            :max-size="2048"
                            :on-format-error="handleFormatError"
                            :on-exceeded-size="handleMaxSize"
                            :action="uploadUrl"
                            style="display: inline-block;width:80px; ">                           
                            <div style="padding: 1px 0;width:80px;height:80px;">
                                <el-image src="/assets/basedict/nopic.png" style="margin-top:50px;padding-left:40px;"  ></el-image> 
                            </div>                                                       
                        </Upload> 
                    </el-link>
                </div>                                                       
                
                <el-dialog :visible.sync="showViewer" width="70vh" append-to-body>
                    <div slot="title" :style="{'background':'#409eff','color':'white','height':'40px'}">
                        <span style="line-height:40px;height:40px;font-size:16px;padding-left:5px;">预览</span>
                    </div>
                    <el-image fit="scale-down"  style=" margin:5px auto;height:500px;width:100% " :src="listurl"></el-image>
                </el-dialog>
            </div>
        </span>
        <!-- 数据手册:38814  -->
        <span v-if="gpitem.gpno==='38814'"> 
            <div style="display:flex" >   
                <div v-if="pdfArr.length<1" style=" width:140px;height:150px;border:1px dashed #ccc ">
                    <div style="margin:35px auto;width:80px;height:80px; ">
                        <el-link :underline="false"  >
                            <Upload
                                ref="uppdf"
                                name="upfile"
                                :data="uploadData"
                                :show-upload-list="false"
                                :before-upload="beforePdfFile"
                                :on-success="successImgFile"
                                :on-progress="handleProgress"
                                accept="pdf"   
                                :disabled="!saveFlag"                      
                                :format="['pdf' ]"
                                :max-size="10240"
                                :on-format-error="pdfFormatError"
                                :on-exceeded-size="pdfMaxSize"
                                :action="uploadUrl"
                                style="display: inline-block;width:80px; ">                           
                                <div style="padding: 1px 0;width:80px;height:80px;">
                                    <el-image src="/assets/basedict/nopic.png" style="margin-top:30px;padding-left:20px;"  ></el-image> 
                                </div>                                                       
                            </Upload> 
                        </el-link>
                    </div>
                </div>                                                       
                <div v-for="(item,index) in pdfArr" :key="index" style="display:flex;flex-direction:column;height:150px;margin-left:100px;"  >
                    <div style="height:35px;background:#e8eaec;width:200px; display:flex;justify-content:center">
                        <el-link :underline="false" style="margin-right:100px;" @click="pdfViewer(item.FILEPATH)"><Icon type="ios-search" />查看</el-link>
                        <el-link :disabled="!saveFlag?true:false" :underline="false" @click="pdfDel(item.IDSEQ,item.LSTSEQ,item.FILEPATH,index)"><Icon type="md-close" /> 删除</el-link>
                    </div> 
                    <div style="width:200px;height:110px;border:1px solid #e8eaec;overflow-y:hidden">
                        <iframe :src="item.FILEPATH" frameborder="0" style="width: 100%; height: 100%"></iframe>
                    </div>
                </div> 
            </div>
        </span>
        <!-- 主图视频:38815  -->
        <span v-if="gpitem.gpno==='38815'"> 
            <div style="display:flex" >  
                <div v-if="videoArr.length<1" style=" width:250px;height:250px;border:1px dashed #ccc ">
                    <div style="margin:65px auto;width:120px;height:120px; ">
                        <el-link :underline="false"  >
                            <Upload
                                ref="upvideo"
                                name="upfile"
                                :data="uploadData"
                                :show-upload-list="false"
                                :before-upload="beforeVideoFile"
                                :on-success="successImgFile"
                                :on-progress="handleProgress"
                                accept="mp4"   
                                :disabled="!saveFlag"                      
                                :format="['mp4' ]"
                                :max-size="20480"
                                :on-format-error="vdFormatError"
                                :on-exceeded-size="vdMaxSize"
                                :action="uploadUrl"
                                style="display: inline-block;width:80px; ">                           
                                <div style="padding: 1px 0;width:80px;height:80px;">
                                    <el-image src="/assets/basedict/nopic.png" style="margin-top:50px;padding-left:40px;"  ></el-image> 
                                </div>                                                       
                            </Upload> 
                        </el-link>
                    </div>
                </div> 
                
                <div  v-for="(item,index) in videoArr" :key="index" style="display:flex;flex-direction:column;height:250px;margin-left:100px;"  >
                    <div style="height:35px;background:#e8eaec;width:400px; display:flex;justify-content:center">
                        <el-link :disabled="!saveFlag?true:false" :underline="false" @click="videoDel(item.IDSEQ,item.LSTSEQ,item.FILEPATH,index)" ><Icon type="md-close" /> 删除</el-link>
                    </div>
                    <div style="width:400px;height:250px;border:1px solid #e8eaec;overflow-y:hidden">  
                        <video-player v-if="videoArr.length>0"  class="video-player vjs-custom-skin" 
                                        ref="videoPlayer" 
                                        :playsline="false" 
                                        :options="playerOptions">
                        </video-player>
                    </div>
                </div>
            </div>
        </span>        
    </el-card>
 
    <div style="height:40px;line-height:35px;width:99%;bottom:0px;position:absolute; background:#ccc" >
        <div  style="float:right;padding:3px;"> 
            <el-button type="primary" size="mini"   @click="modal_exit"  icon="el-icon-close" style=" margin-right:45px;font-size:12px;">取消</el-button>           
            <el-button   type="primary" size="mini"  @click="modal_ok"   icon="iconfont icon-baocun" style=" margin-right:20px;font-size:12px;">保存</el-button>            
        </div>
    </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="450px" append-to-body>
        <div slot="title" :style="{'background':'#409eff','color':'white','height':'40px'}">
            <span style="line-height:40px;height:40px;font-size:16px;padding-left:5px;">提示</span>
        </div>
        <div   >{{prompt}} </div>
        <span slot="footer"  :style="{'height':'40px' }">
            <el-button size="small" type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
    </el-dialog>
 
    <el-dialog  :visible.sync="mgtModel" width="910px" append-to-body>
        <span style="color:#409EFF;font-size:14px;font-weight:600">所选类目：{{matRow.MATTYNM}}</span>
        <el-button @click="addParam()" type="text" size="small" plain icon="el-icon-plus" style="margin-right:80px;float:right"> 新增产品参数</el-button>
        <el-table  :data="propData" 
              border                            
              size="small"
              ref="proptab"
              :height="420" 
              highlight-current-row
              style="overflow:auto;margin-top:1px;"> 
                <el-table-column 
                    prop="CNAME"
                    label="参数项名称"
                    show-overflow-tooltip
                    align="center"                                  
                    sortable
                    width="210">
                </el-table-column> 
                <el-table-column  
                    prop="ENAME"
                    label="参数项英文名称"
                    show-overflow-tooltip
                    align="center"                                  
                    sortable
                    width="290">
                </el-table-column>
                <el-table-column  
                    prop="STFG"
                    label="启用"
                    align="center"                                  
                    sortable
                    width="80">
                    <template slot-scope="scope" >
                        <el-checkbox disabled false-label="" true-label="Y" v-model="scope.row.STFG" >&nbsp;</el-checkbox>
                    </template>
                </el-table-column> 
 
              <el-table-column
                fixed="right"
                label="操作"
                align="center"
                width="250">
                <template slot-scope="scope">
                    <el-button size="small"   type="text" icon="el-icon-edit" @click="modRec(scope.row,scope.$index)">编辑</el-button>
                    <el-button size="small"   type="text" icon="el-icon-check" @click="openRec(scope.row,scope.$index)">启用</el-button>
                    <el-button size="small"  type="text" icon="el-icon-close" @click="stopRec(scope.row,scope.$index)">停用</el-button>  
                    <el-button size="small" type="text" icon="el-icon-setting" @click="mgtRec(scope.row,scope.$index)">管理值</el-button> 
                </template>
              </el-table-column>                       
          </el-table>
          <div style="display:flex; margin-top:10px;">
              <span style="color:#2db7f5;font-size:12px;margin-right:200px;">总共 {{specForm.CNT}} 条记录 </span>
              <el-button style="margin-right:200px;" size="small" plain icon="el-icon-close" @click="mgtModel=false">取消</el-button>
              <el-button type="primary" size="small" plain icon="el-icon-check" @click="mgtSave">保存</el-button>
          </div>
    </el-dialog>
    <el-dialog  :visible.sync="editParam" width="510px" append-to-body>
        <div style="margin-bottom:20px;display:flex">
            <div style="font-size:14px;color:#409EFF;margin-right:6px;width:120px">参数项名称</div>
            <el-input  type="text"   v-model="paramVal" style="width:300px;"></el-input>
        </div>
        <div style="margin-bottom:20px;display:flex">
            <div style="font-size:14px;color:#409EFF;margin-right:6px;width:120px">参数项英文名称</div>
            <el-input  type="text"   v-model="paramEval" style="width:300px;"></el-input>
        </div>
        <div style="margin-bottom:10px;display:flex">
             <el-button type="primary" style="width:100%" icon="el-icon-check" @click="paramSave">确定</el-button>
        </div>
    </el-dialog>
    <el-dialog  :visible.sync="specModel" width="910px" append-to-body>
            <span style="color:#409EFF;font-size:14px;font-weight:600">参数项名称：{{specForm.PROPNM}}</span>
            <el-button @click="addSpec()" type="text" size="small" plain icon="el-icon-plus" style="margin-right:80px;float:right"> 新增参数值</el-button>
            <el-table  :data="specData" 
                border                            
                size="small"
                :height="420" 
                highlight-current-row
                style="overflow:auto;margin-top:1px;"> 
                    <el-table-column 
                        prop="CNAME"
                        label="参数中文值"
                        show-overflow-tooltip
                        align="center"                                  
                        sortable
                        width="260">
                    </el-table-column> 
                    <el-table-column  
                        prop="ENAME"
                        label="参数英文值"
                        show-overflow-tooltip
                        align="center"                                  
                        sortable
                        width="260">
                    </el-table-column>
                    <el-table-column  
                        prop="SORT"
                        label="排序"
                        align="center"                                  
                        sortable
                        width="80">
                        <template slot-scope="scope" >
                            <el-button size="small" v-if="scope.$index>0"  type="text" icon="el-icon-top" @click="specUp(scope.row,scope.$index)"></el-button>
                            <el-button size="small" v-if="scope.$index<specData.length-1"  type="text" icon="el-icon-bottom" @click="specDown(scope.row,scope.$index)"></el-button>
                        </template>
                    </el-table-column> 
                <el-table-column
                    fixed="right"
                    label="操作"
                    align="center"
                    width="140">
                    <template slot-scope="scope">
                        <el-button size="small"   type="text" icon="el-icon-edit" @click="modSpec(scope.row,scope.$index)">编辑</el-button>
                        <el-button size="small"   type="text" icon="el-icon-check" @click="delRec(scope.row,scope.$index)">删除</el-button>
                    </template>
                </el-table-column>                       
            </el-table>
            <div style="display:flex; margin-top:10px;">
                <span style="color:#2db7f5;font-size:12px;margin-right:200px;">总共 {{specForm.COUNT}} 条记录 </span>
                <el-button style="margin-right:200px;" size="small" plain icon="el-icon-close" @click="specModel=false">取消</el-button>
                <el-button type="primary" size="small" plain icon="el-icon-check" @click="specSave">保存</el-button>
            </div>
        </el-dialog>
        <el-dialog  :visible.sync="editSpec" width="510px" append-to-body>
 
            <div style="margin-bottom:20px;display:flex">
                <div style="font-size:14px;color:#409EFF;margin-right:6px;width:120px">参数中文值</div>
                <el-input  type="text"   v-model="specVal" style="width:300px;"></el-input>
            </div>
            <div style="margin-bottom:20px;display:flex">
                <div style="font-size:14px;color:#409EFF;margin-right:6px;width:120px">参数英文值</div>
                <el-input  type="text"   v-model="specEval" style="width:300px;"></el-input>
            </div>
            <div style="margin-bottom:10px;display:flex">
                <el-button type="primary" style="width:100%" icon="el-icon-check" @click="spvalSave">确定</el-button>
            </div>
        </el-dialog>
        <el-dialog    :visible.sync="videoModel" width="450px" append-to-body>
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">视频说明</span>
            </div>
            <div style="height:120px; width:100%;font-size:14px;color:#2b85e4">
                <ul>
                    <li style="margin-bottom:15px">
                        1、时间不宜太长，建议60秒以内;
                    </li>
                    <li style="margin-bottom:15px">
                        2、尺寸：建议 1：1 或 16：9,利于客户的观赏体验;
                    </li>
                    <li style="margin-bottom:15px">
                        3、文件格式最好为MP4,建议大小不超过100M;
                    </li>
                </ul>
            </div>
            <div slot="footer" style="margin-bottom:10px;display:flex">
                <el-button type="primary" style="width:100%" icon="el-icon-check" @click="videoModel=false">我知道了</el-button>
            </div>
        </el-dialog>
 
        <!-- 上传进度 -->
        <el-dialog :visible.sync="videoFlag" width="150px" append-to-body :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false">
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'30px'}">
                <span style="line-height:30px;height:30px;font-size:16px;padding-left:5px;">正在上传中...</span>
            </div>
            <el-progress   type="circle" :percentage="videoUploadPercent"  ></el-progress>  
        </el-dialog>
  </div>
</template>
<script>
    import { getTabColOrData,getBaseData } from '../../api/user';
    import { paramData,paramBase ,getDate,paramData2 } from '../../api/Select'
    import pdf from 'vue-pdf'
    export default {
    data() {
        return {
            listurl:'',//放大的图片
 
            pdfArr:[],
            videoArr:[],
            dialogVisible:false,
            addModal:false,
 
            othBool:false, //修改他人权限
            prompt:'',
            rowChk:'',
            addFlag:'产品详情',
            matloading:false,
            showViewer:false,//打开预览/关闭
            saveFlag:true,
            addtype:'',
            scale: 100, //放大系数
 
            videoModel:false,
            loading:false,
            pdfNum: 1,
            pageTotalNum: 1,  
            loadedRatio: 0, // 当前页面的加载进度，范围是0-1 ，等于1的时候代表当前页已经完全加载完成了
            specModel:false,
            editSpec:false,
            propData:[],
            cfmVal:'',
            paramFlag:'', // 产品参数标志：新增或修改
            specFlag:'',
            prdType:[], //产品库级联数据源
            tabFrmCol:[] ,//表单列集合
            rowSize:15,
 
            // 初始化信息总条数
            dataCount:0,
            prcModel:false,
            mgtModel:false,
            goPage:'',
            stfgSet:'ALL',
            videoSrc:'',
            paramRow:{},
            isShow:true,
            specArr:[],
            ruleValidate: {
               "MATNM":[{"required":true,"message":"产品名称不能为空","trigger":"change"}],
               "MATTY":[{"required":true,"message":"选择类目不能为空","trigger":"change"}],
               "MODELNO":[{"required":true,"message":"所属类别不能为空","trigger":"change"}],
               "PRC":[{"required":true,"message":"制造商进货价不能为空","trigger":"change"}]
            },
            type:'create',
            prcFlag:'create',
            paramTree:'',
            imgSrc:'',
            LANG:'',
            Accept: "png,jpg,jpeg,mp4", //上传文件格式限制
            treeData:[] ,//  树数据
 
            matForm:[], //产品表单列（表单隐藏列）
            payCol:[], // 
 
            uploadData:{}, //上传数据
            upFile:{},
            matRow:{}, //产品行
            specRow:{}, //规格行
            imgRow:{}, //图片行
            prcRow:{}, //价格行
            imgArr:[],  //图片类型
            modelArr:[],  //产品分类明细
            selparam:'IDSEQ',// select 绑定值
            editParam:false,
            textparam:'', // input 绑定值
            paramVal:'', // 参数项值
            paramEval:'',
            specVal:'', // 参数项值
            specEval:'',
            paramStfg:'',
            paramIndex:'',
            specIndex:'',
            defaultProps: 
            {  //el-tree 默认字段属性
                children: 'children',
                id:'id',
                icon:'icon',
                label: 'label'
            },
            supArr:[], //供应商集合
            idseq:'-1',
            filterText: '',
            matData:[] , //产品数据
            befRow:{},
            videoData:[] , //图片视频数据
            allData:[] , //保存产品所有数据
            specData:[] , //规格数据
            prcData:[] , //价格数据
            v_url:this.$store.state.queryUrl , //api请求路径     
            v_formurl:this.$store.state.baseUrl+'user/getFormGroup', //多记录查询 like模式    
            matty:'', //产品类别，由数组变为字符串保存
            propArr:[],
            paramArr:[] , //产品参数 array
            prdonArr:[], //产品状态
            payArr:[], //付款方式
            shpArr:[], //生产货期
            rowIndex :'-1',
            fileName:'',
            typeSeq:'',
            specIdseq:'', //属性参数值主键
            specLstseq:'',//属性参数值主键
            CHKSTFGNM:'',
            specForm:{
                PROPNM:'',
                COUNT:0,
                CNT:0,
            },
            videoUploadPercent:0,
            videoFlag:false,
            playerOptions: {
                playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
                autoplay: false, // 如果true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 导致视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [{
                    type: 'video/mp4', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                    src: '' // url地址 /assets/authorize/tmp4.mp4
                }],
                poster: '', // 你的封面地址
                //width: '100%', // 播放器宽度
                notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true,
                    durationDisplay: true,
                    remainingTimeDisplay: false,
                    fullscreenToggle: true // 全屏按钮
                }
            },
            delImg:[],
            prdRow:'',
            appArr:[],
            certArr:[],
            v_user:this.$cookies.get('v_username'), 
            v_pwd:this.$cookies.get('v_password'),
            uploadUrl:'',
        }
    },
    watch: {
 
    },
    components: {
 
    },
    computed: {
        divHeight: function() {
            return (window.innerHeight - 50) + 'px';
        }, 
        subHeight: function() {
            return (window.innerHeight - 175) + 'px';
        }, 
        BOXCUBE :function(){
            return (this.matRow['BOXL'] *this.matRow['BOXW']*this.matRow['BOXH'])/1000000000 ;
        }
    },
    mounted () {
 
        this.uploadUrl =this.$store.state.baseUrl +'oss/fileUpload?username='+this.v_user+'&password='+this.v_pwd
    },
    created () {    
        //供应商数据
        paramData('STFG','Y' ,'V_COMBO_SUP').then((res)=>{               
            this.supArr =res.data.result
        }); 
        //图片类型
        paramBase('24656','v_sys_base').then((res)=>{
            this.imgArr =res.data.result
        }); 
        //应用领域
        paramBase('20087','v_sys_base').then((res)=>{
            this.appArr =res.data.result
        }); 
        getTabColOrData(this.v_formurl,this.v_user,this.v_pwd,'v_sys_form_fld','idseq','29','gpnm,gpsort','','','',' order by gpsort').then((res) => {
            this.tabFrmCol = res.data.result             
        }); 
        //产品属性
        paramBase('38540','v_sys_base').then((res)=>{
            this.propArr =res.data.result
        });
        //产品状态
        paramBase('24337','v_sys_base').then((res)=>{
            this.prdonArr =res.data.result
        });
        //生产货期
        paramBase('38868','v_sys_base').then((res)=>{
            this.shpArr =res.data.result
        });
        //付款方式
        paramBase('19672','v_sys_base').then((res)=>{
            this.payArr =res.data.result
        });
        getBaseData(this.$store.state.nologinCasUrl, 'parentno','0','','','V_PRD_CATEGORY','').then(res=>{
            this.prdType=res.data
        })
        //产品认证
        paramData('','' ,'V_PRD_CERT').then((res)=>{               
            this.certArr =res.data.result
        }); 
    },
    methods: {
        // 查看 
        qryRec(row){
            this.saveFlag=true
            let matty =row['MATTY']
            let matty_=matty.substring(matty.indexOf(',')+1)
            //产品分类
            paramData('parentno',matty_,'PRD_CATEGORY').then((res)=>{               
                this.modelArr =res.data.result
            })
            //产品参数   
            this.paramArr=[]
            
            //从规 格中取当取产品的属性
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'user/getPrdSpec?username='+this.v_user+'&password='+this.v_pwd,
                data: {p_key:'matidseq',p_value:row.IDSEQ,p_cond:' order by sortby ',p_table:'V_MAT_SPEC_PROP'},
            }).then(res=>{  
                if (res.data.length>0){    //之前已保存属性，当类别属性增加时要改变
                    this.paramArr =res.data    
                }else{
                    this.getProdParam(matty_) //来自于产品属性库
                }
            }) 
            //行赋值    
            this.matRow =JSON.parse(JSON.stringify(row))  //深拷贝
            this.matty=row.MATTY 
            
            //产品参数值 selSpecValue
            this.paramRow={}
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'user/selSpecValue?username='+this.v_user+'&password='+this.v_pwd,
                data: {p_idseq:row.IDSEQ },
            }).then(res=>{      
                this.paramRow =res.data      
            })
            //产品图片
            this.qryImgData(row.IDSEQ)   
        },
        //上传文件之前的钩子
        beforeImgFile (res, file) {  
            if(this.matRow['IDSEQ'])  {       
                this.uploadData = {
                    p_user:this.v_user,
                    p_idseq:this.matRow['IDSEQ'],
                    p_table:'mat_img',
                    p_filety:'IMG',
                }
                let promise = new Promise(resolve => {
                this.$nextTick(function() {
                        resolve(true);
                    });
                });
                return promise;  
            }else{
                this.dialogVisible=true
                this.prompt='请先保存资料后再上传'
            }
        },
        beforePdfFile (res, file) {  
            if(this.matRow['IDSEQ'])  {       
                this.uploadData = {
                    p_user:this.v_user,
                    p_idseq:this.matRow['IDSEQ'],
                    p_table:'mat_img',
                    p_filety:'PDF',
                }
                let promise = new Promise(resolve => {
                this.$nextTick(function() {
                        resolve(true);
                    });
                });
                return promise;  
            }else{
                this.dialogVisible=true
                this.prompt='请先保存资料后再上传'
            }
        },
        beforeVideoFile (res, file) {  
            if(this.matRow['IDSEQ'])  {       
                this.uploadData = {
                    p_user:this.v_user,
                    p_idseq:this.matRow['IDSEQ'],
                    p_table:'mat_img',
                    p_filety:'VIDEO',
                }
                let promise = new Promise(resolve => {
                this.$nextTick(function() {
                        resolve(true);
                    });
                });
                return promise;  
            }else{
                this.dialogVisible=true
                this.prompt='请先保存资料后再上传'
            }
        },
        //上传显示进度
        handleProgress(event,file,fileList){
            this.videoFlag = true
            this.videoUploadPercent = Math.floor(event.percent)
        },
        //文件超出指定文件大小限制的钩子
        handleMaxSize (file) {
            this.prompt =file.name + ' 文件太大, 不能超出2M'
            this.dialogVisible=true
        },
        pdfMaxSize (file) {
            this.prompt =file.name + ' 文件太大, 不能超出10M'
            this.dialogVisible=true
        },
        vdMaxSize (file) {
            this.prompt =file.name + ' 文件太大, 不能超出20M'
            this.dialogVisible=true
        },
        //文件格式验证错误时的钩子
        handleFormatError (file) {
            this.prompt='文件格式错误,'+file.name + ' 不正确,请选择 jpg or png' 
            this.dialogVisible=true
        },
        pdfFormatError (file) {
            this.prompt='文件格式错误,'+file.name + ' 不正确,请选择 pdf' 
            this.dialogVisible=true
        },
        vdFormatError (file) {
            this.prompt='文件格式错误,'+file.name + ' 不正确,请选择 mp4' 
            this.dialogVisible=true
        },
        // 文件上传成功时的钩子
        successImgFile(res,file){
            this.videoFlag = false
            this.videoUploadPercent=0
            this.qryImgData(this.matRow['IDSEQ'])
        },
  
  
        videoRmk(){
            this.videoModel=true
        },
        //PDF删除
        pdfDel(idseq,lstseq,path,index){
            this.$confirm('你确定要删除数据手册吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                //发起删除请求
                this.pdfArr.splice(index, 1); 
                this.delImg.push({IDSEQ:idseq,LSTSEQ:lstseq,PATH:path.replace('https://kreco.oss-accelerate.aliyuncs.com/','')})         
            }).catch(()=>{})
        },
        //视频删除
        videoDel(idseq,lstseq,path,index){
            this.$confirm('你确定要删除主图视频吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                //发起删除请求
                this.videoArr.splice(index, 1); 
                this.delImg.push({IDSEQ:idseq,LSTSEQ:lstseq,PATH:path.replace('https://kreco.oss-accelerate.aliyuncs.com/','')})         
            }).catch(()=>{})
        },
        //预览PDF
        pdfViewer(filePath){
            let routeUrl = this.$router.resolve({
                path: '/fullPreview',
                query: {lang:this.LANG,fileUrl:filePath},
            });
            window.open(routeUrl.href, '_blank');
        },
 
        //删除 规格记录
        delRec(row,index){ 
            this.$confirm('你确定要删除该参数值?删除后对应的产品该值将被清空','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                var vm=this;
                if(row.IDSEQ ) {//+":"+row.LSTSEQ+':'+row.ITMSEQ)
                    this.$axios({
                        method: 'post',
                        url:this.$store.state.baseUrl+'sysprivs/delTableRec?username='+this.v_user +'&password='+this.v_pwd,
                        data: {p_key:'idseq',p_value:row.IDSEQ,p_key2:'lstseq',p_value2:row.LSTSEQ,p_key3:'ITMSEQ',p_value3:row.ITMSEQ,p_table:'PRD_PROPDTL'},
                    }).then(res=>{      
                        if(res.data.code =='200')  {  
                            this.specData.splice(index,1)         
                        }else{
                            this.dialogVisible =true
                            this.prompt=res.data.message
                        }        
                    }) 
                } 
            }).catch(()=>{})  
        },
        //属性值修改
        modSpec(row,index){           
            this.editSpec =true
            this.specVal =row.CNAME
            this.specEval =row.ENAME
            this.specIndex =index
            this.specFlag='edit'
        },
        //属性值暂存到数组中
        spvalSave(){
            if(!this.specVal){
                this.dialogVisible =true
                this.prompt='参数中文值不能为空'
            }else{
                let obj={CNAME:this.specVal,ENAME:this.specEval }
                let bool=false
                for(var i = 0; i < this.specData.length - 1; i++) {
                    if(this.specData[i].CNAME.toUpperCase() ==this.specVal.toUpperCase()) {
                        this.dialogVisible =true
                        this.prompt='该值已存在'
                        bool =true
                    }
                }
                if (!bool){
                    if (this.specFlag ==='edit'){
                        this.specData.splice(this.specIndex,1,obj)
                    }else{
                        this.specData.splice(this.specData.length,0,obj) 
                    }
                    this.editSpec =false
                }
            }
        },
        //属性值保存到数据库
        specSave(){
            this.specModel=false
            //类别属性 排序、名称、启用与否 编辑保存
            for(let k=0;k<this.specData.length;k++) {
                let saveArr=[]
                saveArr.push(this.specData[k])
                this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'user/propDtlSave?username='+this.v_user +'&password='+this.v_pwd,
                    data: {p_json:JSON.stringify(saveArr), p_table:'PRD_PROPDTL',p_idseq:this.specIdseq,p_lstseq:this.specLstseq},
                })
            }
            this.getProdParam(this.specIdseq)    
        },
        //规格上移
        specUp(row,index){
            if (index>0){
                let x=index,y=index-1
                this.specData.splice(x , 1, ...this.specData.splice(y  , 1, this.specData[x]))
            }   
        },
        //规格下移
        specDown(row,index){
            if (index<this.specData.length-1 && this.specData.length>1){
                let x=index+1,y=index
                this.specData.splice(x , 1, ...this.specData.splice(y  , 1, this.specData[x]))
            }  
        },
        addSpec(){
            this.editSpec =true
            this.specVal  =''
            this.specEval =''
            this.specFlag ='add'
        },
        //属性值 新增修改
        mgtRec(row,index){
            if (row.LSTSEQ){
                this.specForm.PROPNM=row.CNAME
                this.specModel=true
                this.specIdseq =row.IDSEQ
                this.specLstseq=row.LSTSEQ
                paramData2('IDSEQ',row.IDSEQ,'LSTSEQ',row.LSTSEQ,'V_PRD_PROPDTL').then((res)=>{               
                        this.specData =res.data.result
                        this.specForm.COUNT=res.data.result.length
                }); 
            }else{
                this.dialogVisible =true
                this.prompt='记录未保存不能操作'
            }
        },
        //重新刷新产品属性和 属性值
        getProdParam(idseq){
            //产品参数   
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'user/getPrdSpec?username='+this.v_user +'&password='+this.v_pwd,
                data: {p_key:'idseq',p_value:idseq,p_cond:' order by sortby,lstseq',p_table:'V_PRD_CATEPROP'},
            }).then(res=>{      
                this.paramArr =res.data   
            }) 
        },
        //管理参数保存
        mgtSave(){
            this.mgtModel=false
            //类别属性 排序、名称、启用与否 编辑保存
            this.$axios({   
                method: 'post',
                url:this.$store.state.baseUrl+'user/matPropSave?username='+this.v_user +'&password='+this.v_pwd,
                data: {p_json:JSON.stringify(this.propData), p_table:'PRD_CATEPROP',p_idseq:this.typeSeq},
            }).then(res=>{ 
                if (res.data.result==='OK'){
                    //对参数数组重新查询
                    this.getProdParam(this.typeSeq)
                }
            })
        },
 
        //产品参数确定 暂存数组中  
        paramSave(){
            if(!this.paramVal){
                this.dialogVisible =true
                this.prompt='参数项名称不能为空' 
            }else{
                let bool=false
                for(var i = 0; i < this.propData.length - 1; i++) {
                    if(this.propData[i].CNAME.toUpperCase() ==this.paramVal.toUpperCase()) {
                        this.dialogVisible =true
                        this.prompt='该值已存在'
                        bool =true
                    }
                }
                if (!bool){
                    if (this.paramFlag ==='edit'){
                        this.propData.splice(this.paramIndex,1,{CNAME:this.paramVal,ENAME:this.paramEval,STFG:this.paramStfg})
                    }else{
                        this.propData.splice(this.propData.length,0,{CNAME:this.paramVal,ENAME:this.paramEval,STFG:this.paramStfg}) 
                    }
                    this.editParam =false
                }
            }
        },
        //新增产品参数
        addParam(){
            this.editParam =true
            this.paramVal =''
            this.paramEval =''
            this.paramStfg ='Y'
            this.paramFlag='add'
        },
        //参数项修改
        modRec(row,index){
            this.editParam =true
            this.paramVal =row.CNAME
            this.paramEval =row.ENAME
            this.paramStfg =row.STFG
            this.paramIndex =index
            this.paramFlag='edit'
        },
        //启用参数
        openRec(row,index){
            Object.assign(row,{STFG:'Y'})
        },
        //停用参数
        stopRec(row,index){
            Object.assign(row,{STFG:'N'})
        },
        //管理参数界面
        mgtParam(){
            if (!this.matty){
                this.dialogVisible =true
                this.prompt='请选择类目'
            }else{
                this.mgtModel =true
                this.typeSeq =this.matty.substring(this.matty.indexOf(',')+1)
                paramData('IDSEQ',this.typeSeq,'V_PRD_CATEPROP').then((res)=>{               
                    this.propData =res.data.result
                    this.specForm.CNT =res.data.result.length
                    if (res.data.result.length>0)
                    this.matRow.MATTYNM =res.data.result[0].FCNAME
                }); 
            }
        },
        closeViewer(){ //关闭
            this.showViewer = false
        },
        //图片预览
        imgPreview(path){
            this.showViewer=true
            this.listurl=path
        },
        imgDel(idseq,lstseq,path,index){
            this.$confirm('你确定要删除此图片吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                //发起删除请求
                this.videoData.splice(index, 1); 
                this.delImg.push({IDSEQ:idseq,LSTSEQ:lstseq,PATH:path.replace('https://kreco.oss-accelerate.aliyuncs.com/','')})         
            }).catch(()=>{})
        },
 
        // 产品库 变更时 要重新构建 产品分类 和产品参数
        casChange(value ){
            this.paramRow={}
            if (value ){
                this.matty =value.join(',')     
                //产品分类
                paramData('parentno',value[1],'PRD_CATEGORY').then((res)=>{               
                    this.modelArr =res.data.result
                    this.matRow.MODELNO=''
                });                      
                //产品参数   
                this.getProdParam(value[1])
            }  
        },
        modelChange(){
            this.$forceUpdate()
        },
        inputChange(){
            this.$forceUpdate()
        },
 
        //保存记录
        modal_ok(){   
            if(!this.matRow.MATTY){ 
                this.dialogVisible =true
                this.prompt='类目不能为空'             
            } else if(!this.matRow.MODELNO){
                this.dialogVisible =true
                this.prompt='所属类别不能为空'               
            }else if(!this.matRow.MATNM){
                this.dialogVisible =true
                this.prompt='产品名称不能为空'               
            }else{ 
                let propseq=this.matRow.MATTY[1]
                if(typeof(this.matRow['APPFIELD'])==='object'){
                        Object.assign(this.matRow,{ MATTY: this.matRow.MATTY.join(','),APPFIELD:this.matRow['APPFIELD'].join(',')} )
                }else{
                    Object.assign(this.matRow,{ MATTY: this.matRow.MATTY.join(',') } )
                }
                if(typeof(this.matRow['PRDCERT'])==='object'){
                    Object.assign(this.matRow,{ 'PRDCERT':this.matRow['PRDCERT'].join(',')} )
                } 
                var vm =this
                this.$axios({  //传入 p_frmid 参数是为了生成 表单 的编码
                    method: 'post',
                    url:vm.$store.state.baseUrl+'sysprivs/formOneSave?username='+vm.v_user+'&password='+vm.v_pwd,
                    data: {p_json:JSON.stringify(vm.matRow), p_table:'bs_mat',frmid:'29'},
                }).then(res=>{      
                    if(res.data.result ==='OK')  {   
                        let idseq_=res.data.idseq                              
                        //2、参数、参数值保存
                        this.$axios({   
                            method: 'post',
                            url:vm.$store.state.baseUrl+'user/matSpecSave?username='+this.v_user +'&password='+this.v_pwd,
                            data: {p_json:JSON.stringify(vm.paramRow), p_table:'MAT_SPEC',p_idseq:idseq_,idty:propseq},
                        }).then(res=>{
                            if (res.data.code!=='200'){
                                 //
                            }
                        })
                        //3、图片记录删除
                         if (this.delImg.length>0) {
                            this.$axios({   
                                method: 'post',
                                url:this.$store.state.baseUrl+'user/batDtlSave?username='+this.v_user +'&password='+this.v_pwd,
                                data: {p_json:JSON.stringify(this.delImg), p_table:'mat_img',p_idseq:idseq_,p_action:'D' },
                            })
                            for (let k=0;k<this.delImg.length;k++){
                                this.$axios({   
                                    method: 'post',
                                    url:this.$store.state.baseUrl+'oss/deleteFile?username='+this.v_user +'&password='+this.v_pwd,
                                    data: {p_key:this.delImg[k].PATH},
                                }).then(res=>{
                                    console.log(res.data.code)
                                })
                            }
                         }         
                         this.$emit('child-save',idseq_)                       
                    }else{
                        this.dialogVisible =true
                        this.prompt=res.data.result
                    }        
                }) 
            }            
        },
        modal_exit(){   
            this.$emit('child-exit')             
        },
        //新增记录
        addRec(obj){
 
            this.saveFlag =true
            this.matRow ={'IDSEQ':'','MATPROP':'标品','MATNM':obj['MATNM']}
            this.imgSrc='/assets/basedict/no_confirm.png'
            this.pdfArr=[]
            this.videoArr=[]
            this.paramArr=[]
            this.videoData=[]
        },
 
        //查询图片数据
        qryImgData(idseq){
            getTabColOrData(this.v_url,this.v_user,this.v_pwd,'V_mat_img','idseq',idseq,'','').then((res) => {
                this.videoData=[]
                this.pdfArr=[]
                this.videoArr=[]
                for (let k=0; k<res.data.length;k++){
                    if (res.data[k].FILETY==='IMG'){
                        this.videoData.push(res.data[k])
                    }else if (res.data[k].FILETY==='PDF'){
                        this.pdfArr.push(res.data[k])
                    }else if (res.data[k].FILETY==='VIDEO'){
                        this.videoArr.push(res.data[k])
                        this.playerOptions.sources[0].src =res.data[k].FILEPATH
                    }    
                }
            })
        },
    },
 }
</script>

<style scoped lang="less">
 
    .el-cascader-menu{
        height:400px;
        width:100%;
    }
    .item-width  {
        width: 48%;
        color:#409EFF;
    }
    .video-js .vjs-icon-placeholder {
        width: 80%;
        height: 80%;
        display: block;
    }
 
</style> 
<style lang="less">
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
    //.el-dialog__headerbtn i { background:white;font-size: 20px;  }
 
    .item-three  {
        width: 32%;
        color:#409EFF;
    }

    .row-width  {
        width: 98.5%;
    }
 
    .el-table {
        th {
            padding: 0 ;
        }
        thead{
            height: 15px;
            color:rgb(11, 11, 59);
            font-size:12px;
            background:rebeccapurple;
        }
        td {
            padding: 5px ;
            font-size:11px;
        } 
    }
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
        border-color :#3d77eb;
    }
    .el-checkbox__input.is-disabled+span.el-checkbox__label{
        color:#3d77eb
    }
 
    .messageBox .el-message-box__content{
        border-top: 1px solid #EBEEF5;
        height: 50px;
    }
    .el-message-box__message p{
        line-height: 25px;
    }
    .messageBox .el-message-box__btns{
        padding: 20px 15px 0;
    }
    .messageBox .el-message-box__header{
        background:#409eff;
    }
    .messageBox .el-message-box__title{
        color:white ;
        font-size:14px;
    }
</style>
 
